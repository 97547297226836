import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'vant/lib/index.css';

//路由操作
router.beforeEach((to, from, next) => {
  // 当移动端试图访问pc端界面时
  if (
    /Android|webOS|iPhone|iPod|BlackBerry|iPad/i.test(navigator.userAgent) &&
    to.meta.type !== "mobile"
  ) {
    const routers = router.options.routes.filter((v) => v.name?.toString().startsWith("m") );
    const path = routers?.filter(
      (v) =>
        v.name?.toString().split("_")[1] === to.name?.toString().split("_")[1]
    )[0];
    if (path) {
      next(path.path);
    } else {
      next("/");
    }
  }

  // 当pc端页面试图访问移动端页面时
  if (
    !/Android|webOS|iPhone|iPod|BlackBerry|iPad/i.test(navigator.userAgent) &&
    to.meta.type !== "pc"
  ) {
    const routers = router.options.routes;
    const path = routers.filter(
      (v) =>
        v.name?.toString().split("_")[1] === to.name?.toString().split("_")[1]
    )[0].path;
    if (path) {
      next(path);
    } else {
      next("/");
    }
  }
  next();
});

createApp(App).use(store).use(router).mount('#app');