import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'p_register',
    component: () => import('@/views/p_register/RegisterView.vue'),
	meta: {type: "pc"}
  },
  {
    path: '/m_register',
    name: 'm_register',
    component: () => import('@/views/m_register/RegisterView.vue'),
	meta: {type: "mobile"}
  },
  {
    path: '/p_find',
    name: 'p_find',
    component: () => import('@/views/p_find/FindView.vue'),
	meta: {type: "pc"}
  },
  {
    path: '/m_find',
    name: 'm_find',
    component: () => import('@/views/m_find/FindView.vue'),
	meta: {type: "mobile"}
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
